import React from 'react';
import "../components/styles/NotFound.css"


export default function PageNotFound(){
    return (
        <div className='not-found-container'>
            <h1>&lt;404/&gt;</h1>
            <h2>Page Not Found</h2>
        </div>
    )
}